<template>
  <g id="g17">
    <g id="g324" class="dut_ma11">
      <TestPointView
        ref="testpoint_view"
        :testpointPlot="testpointPlot"
        :renderScale="renderScale"
        :sliderScale="sliderScale"
        :svgScale="1"
        :x_offset="2340"
        :y_offset="12617"
        :width="9522"
        :height="10750"
        :probeElement="probeElement"
        :btnRenderSide="btnRenderSide"
        :showRefs="showRefs"
        :searchRefString="searchRefString"
        :refSlider="refSlider"
        key="ma_11_tp"
        @toggleHoverText="toggleHoverText"
      />
      <GuidePinView
        ref="guidepin_view"
        :guidepinPlot="guidePins"
        :renderScale="renderScale"
        :sliderScale="sliderScale"
        :svgScale="1"
        :x_offset="2340"
        :y_offset="12617"
        :width="9522"
        :height="10750"
        :btnRenderSide="btnRenderSide"
        :showRefs="showRefs"
        :searchRefString="searchRefString"
        :refSlider="refSlider"
        key="ma_11_gp"
        @toggleHoverText="toggleHoverText"
      />
    </g>
    <IngunMa11Top v-show="showPlate && sideToggle != 2" />
    <IngunMa11Bottom v-show="showPlate && sideToggle != 1" />
  </g>
</template>
<script>
import interact from "interactjs";
export default {
  name: "IngunMa11",
  props: {
    testpointPlot: Array,
    guidePins: Array,
    renderScale: Number,
    svgScale: Number,
    sliderScale: Number,
    probeElement: Object,
    sideToggle: Number,
    btnRenderSide: Number,
    showRefs: Boolean,
    showPlate: Boolean,
    searchRefString: String,
    refSlider: Number,
  },
  components: {
    TestPointView: () => import("./TestPointView.vue"),
    GuidePinView: () => import("./GuidePinView.vue"),
    IngunMa11Top: () => import("./IngunMa11Top.vue"),
    IngunMa11Bottom: () => import("./IngunMa11Bottom.vue"),
  },
  data() {
    return {
      position: {
        x: 0,
        y: 0,
      },
    };
  },
  methods: {
    activateDragAndDrop() {
      interact(`.dut_ma11`).draggable({
        onmove: (event) => {
          this.position.x += event.dx * 12;
          this.position.y -= event.dy * 12;
          event.target.style.transform = `translate(${this.position.x}px, ${this.position.y}px)`;
        },
      });
    },
    toggleHoverText(payload) {
      this.$emit("toggleHoverText", payload);
    },
  },
  mounted() {
    this.activateDragAndDrop();
  },
};
</script>
